<template>
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
      <div class="bg-white rounded-2xl shadow-2xl w-full max-w-md max-h-[95vh] flex flex-col">
        <div class="p-4 sm:p-6 flex-grow overflow-y-auto">
          <h3 class="text-lg font-semibold text-gray-900 mb-4 text-center">编辑记账</h3>
          <form @submit.prevent="submitForm" class="space-y-4">
            <div class="mb-4">
              <ul class="flex border-b justify-center">
                <li>
                  <a :class="{'border-blue-500 text-blue-600 bg-blue-50': editedEntry.type === 'expense', 'border-green-500 text-green-600 bg-green-50': editedEntry.type === 'income'}"
                     class="inline-block py-2 px-4 text-sm font-semibold cursor-default border-b-2 transition-all duration-300 rounded-t-lg">
                    {{ editedEntry.type === 'expense' ? '支出' : '收入' }}
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <label class="block text-gray-700 text-xs font-medium mb-1" for="date">日期</label>
              <input type="date" v-model="editedEntry.date" class="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"/>
            </div>
            <div>
              <label class="block text-gray-700 text-xs font-medium mb-1" for="amount">金额</label>
              <div class="relative">
                <input type="number" v-model="editedEntry.amount" step="0.01" class="w-full pl-3 pr-12 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300" placeholder="请输入金额"/>
                <span class="absolute right-3 top-2.5 text-gray-400 text-sm">元</span>
              </div>
            </div>
            <div>
              <label class="block text-gray-700 text-xs font-medium mb-1">分类</label>
              <div class="grid grid-cols-4 gap-2">
                <div v-for="category in filteredCategories" :key="category"
                     @click="selectCategory(category)"
                     :class="{'ring-2 ring-blue-500 bg-blue-50': editedEntry.category === category, 'hover:bg-gray-50': editedEntry.category !== category}"
                     class="border p-2 rounded-lg cursor-pointer transition-all duration-100 flex flex-col items-center justify-center">
                  <span class="text-xl mb-0.5">{{ getEmoji(category) }}</span>
                  <span class="text-xs">{{ category }}</span>
                </div>
              </div>
            </div>
            <div>
              <label class="block text-gray-700 text-xs font-medium mb-1" for="note">备注</label>
              <input v-model="editedEntry.note" class="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300" placeholder="添加备注（选填）"/>
            </div>
          </form>
        </div>
        <div class="p-4 bg-gray-50 rounded-b-2xl">
          <div class="flex items-center justify-between space-x-2">
            <button @click="$emit('close')" type="button" class="px-3 py-1.5 text-sm border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 transition-all duration-300">
              取消
            </button>
            <div class="flex space-x-2">
              <button type="button" @click="handleDelete" class="px-3 py-1.5 text-sm bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all duration-300">
                删除
              </button>
              <button @click="submitForm" type="button" class="px-3 py-1.5 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all duration-300">
                保存
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions } from 'vuex'
  import { getEmoji } from '@/utils/util.js' // 确保路径正确
  
  export default {
    props: ['entry'],
    data() {
      return {
        editedEntry: { ...this.entry },
        categories: {
          income: ['工资', '奖金', '退款', '红包', '兼职', '退税', '理财', '报销'],
          expense: ['购物', '餐饮', '交通', '娱乐', '学习', '旅游', '家庭', '订阅']
        }
      }
    },
    computed: {
      filteredCategories() {
        return this.categories[this.editedEntry.type];
      }
    },
    methods: {
      ...mapActions(['editEntry', 'deleteEntry']),
      selectCategory(category) {
        this.editedEntry.category = category;
      },
      submitForm() {
        this.$store.dispatch('editEntry', this.editedEntry);
        this.$emit('close');
      },
      handleDelete() {
        if (confirm('确定要删除这条记录吗？')) {
          this.$store.dispatch('deleteEntry', this.editedEntry.id);
          this.$emit('close');
        }
      },
      getEmoji
    }
  }
  </script>