import { createStore } from 'vuex';
import dayjs from 'dayjs';
import {axiosInstance, axiosInstanceNoAuth} from './axiosInstance';
import axios from 'axios';

// LocalStorage Plugin
const localStoragePlugin = store => {
  store.subscribe((mutation, state) => {
    localStorage.setItem('vuex-entries', JSON.stringify(state.entries));
    localStorage.setItem('vuex-user', JSON.stringify(state.user));
  });
};

// Load initial state from localStorage if available
const savedEntries = JSON.parse(localStorage.getItem('vuex-entries')) || [];
const user = JSON.parse(localStorage.getItem('vuex-user')) || null;

export default createStore({
  state: {
    entries: savedEntries,
    user: user
  },
  mutations: {
    setEntries(state, entries) {
      state.entries = entries;
    },
    addEntry(state, entry) {
      state.entries.push(entry);
    },
    editEntry(state, updatedEntry) {
      const index = state.entries.findIndex(entry => entry.id === updatedEntry.id);
      if (index !== -1) {
        state.entries.splice(index, 1, updatedEntry);
      }
    },
    deleteEntry(state, entryId) {
      state.entries = state.entries.filter(entry => entry.id !== entryId);
    },
    SET_USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    async fetchEntries({ commit }) {
      try {
        const response = await axiosInstance.get('/entries');
        commit('setEntries', response.data);
      } catch (error) {
        console.error('Failed to fetch entries:', error);
      }
    },
    async addEntryStore({ commit }, entry) {
      try {
        commit('addEntry', entry);
        const response = await axiosInstance.post('/entries', entry);
      } catch (error) {
        console.error('Failed to add entry:', error);
      }
    },
    async editEntry({ commit }, updatedEntry) {
      try {
        commit('editEntry', updatedEntry);
        const response = await axiosInstance.put(`/entries/${updatedEntry.id}`, updatedEntry);
      } catch (error) {
        console.error('Failed to edit entry:', error);
      }
    },
    async deleteEntry({ commit }, entryId) {
      try {
        await axiosInstance.delete(`/entries/${entryId}`);
        commit('deleteEntry', entryId);
      } catch (error) {
        console.error('Failed to delete entry:', error);
      }
    },
    async login({ commit }, credentials) {
      // 这里应该调用您的后端 API 进行实际的登录
      // 这只是一个示例
      const response = await axiosInstanceNoAuth.post('/login', credentials);
      if (response.status === 200) {
        commit('SET_USER', response.data);
      }
    },
    logout({ commit }) {
      // 这里应该调用您的后端 API 进行登出
      commit('SET_USER', null);
    },
  },
  getters: {
    getEntries: state => state.entries,
    getRecentEntries: state => {
      return state.entries
          .slice()
          .sort((a, b) => dayjs(b.date).valueOf() - dayjs(a.date).valueOf())
          .slice(0, 5);
    },
    getMonthlyStatistics: state => {
      const currentMonth = dayjs().month();
      const currentYear = dayjs().year();
      const monthlyEntries = state.entries.filter(entry => {
        const entryDate = dayjs(entry.date);
        return entryDate.month() === currentMonth && entryDate.year() === currentYear;
      });

      const income = monthlyEntries
          .filter(entry => entry.type === 'income')
          .reduce((sum, entry) => sum + parseFloat(entry.amount), 0);

      const expense = monthlyEntries
          .filter(entry => entry.type === 'expense')
          .reduce((sum, entry) => sum + parseFloat(entry.amount), 0);

      const balance = income - expense;

      return { income, expense, balance };
    },
    isLoggedIn: state => !!state.user,
    username: state => state.user ? state.user.name : '',
    user_id: state => state.user ? state.user.id : null
  },
  modules: {},
  plugins: [localStoragePlugin]
});
