import axios from 'axios';

// 创建一个 Axios 实例
const axiosInstance = axios.create({
    baseURL: 'https://jizhang-back.jiangnan.eu.org/api', // 设置基础URL
});

// 添加请求拦截器
axiosInstance.interceptors.request.use(
    config => {
        const token = JSON.parse(localStorage.getItem('vuex-user')).token; // 从 localStorage 获取 token
        if (token) {
            config.headers.Authorization = `Bearer ${token}`; // 在请求头中添加 token
        }
        return config;
    },
    error => {
        console.error('Request interceptor error:', error)
        return Promise.reject(error);
    }
);

// 创建一个不包含拦截器的 Axios 实例
const axiosInstanceNoAuth = axios.create({
    baseURL: 'https://jizhang-back.jiangnan.eu.org/api', // 设置基础URL
});

export { axiosInstance, axiosInstanceNoAuth };
