<template>
  <transition name="modal-fade">
    <div class="fixed inset-0 flex items-center justify-center z-50">
      <div class="absolute inset-0 bg-black bg-opacity-50" @click="closeModal"></div>
      <div class="bg-white rounded-lg shadow-xl w-96 max-w-md mx-auto z-10 overflow-hidden transform transition-all"
           :class="{'translate-y-0 opacity-100': show, 'translate-y-4 opacity-0': !show}">
        <div class="bg-gradient-to-r from-blue-500 to-purple-600 p-6">
          <h2 class="text-2xl font-bold text-white mb-2">欢迎登录</h2>
          <p class="text-blue-100">请输入您的账号信息</p>
        </div>
        <form @submit.prevent="handleSubmit" class="p-6">
          <div class="mb-4">
            <label for="username" class="block text-gray-700 text-sm font-bold mb-2">用户名</label>
            <input type="text" id="username" v-model="username" required
                   class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200">
          </div>
          <div class="mb-6">
            <label for="password" class="block text-gray-700 text-sm font-bold mb-2">密码</label>
            <input type="password" id="password" v-model="password" required
                   class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200">
          </div>
          <div class="flex items-center justify-between">
            <button type="submit"
                    class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-200">
              登录
            </button>
            <a href="#" class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800 transition duration-200">
              忘记密码？
            </a>
          </div>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      show: false
    };
  },
  methods: {
    handleSubmit() {
      this.$emit('login', { username: this.username, password: this.password });
    },
    closeModal() {
      this.show = false;
      setTimeout(() => {
        this.$emit('close');
      }, 300);
    }
  },
  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 50);
  }
};
</script>

<style scoped>
.modal-fade-enter-active, .modal-fade-leave-active {
  transition: opacity 0.3s;
}
.modal-fade-enter, .modal-fade-leave-to {
  opacity: 0;
}
</style>