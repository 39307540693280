<template>
    <div class="statistics-page p-6 max-w-6xl mx-auto bg-gray-50">
      <!-- 月度总览 -->
      <div class="overview-section mb-12">
        <h2 class="text-2xl font-semibold mb-6 text-gray-700">本月概览</h2>
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-6">
          <div class="stat-card bg-gradient-to-br from-green-400 to-green-600 p-6 rounded-xl shadow-lg">
            <p class="text-sm text-green-100 mb-2">总收入</p>
            <p class="text-3xl font-bold text-white">¥ {{ monthlyStats.income.toFixed(2) }}</p>
          </div>
          <div class="stat-card bg-gradient-to-br from-red-400 to-red-600 p-6 rounded-xl shadow-lg">
            <p class="text-sm text-red-100 mb-2">总支出</p>
            <p class="text-3xl font-bold text-white">¥ {{ monthlyStats.expense.toFixed(2) }}</p>
          </div>
          <div class="stat-card bg-gradient-to-br from-blue-400 to-blue-600 p-6 rounded-xl shadow-lg">
            <p class="text-sm text-blue-100 mb-2">结余</p>
            <p class="text-3xl font-bold text-white">¥ {{ monthlyStats.balance.toFixed(2) }}</p>
          </div>
        </div>
      </div>
  
      <!-- 收支趋势图 -->
      <div class="trend-chart-section mb-12">
        <h2 class="text-2xl font-semibold mb-6 text-gray-700">收支趋势</h2>
        <div v-if="dataReady" class="bg-white rounded-xl shadow-lg p-6">
          <LineChart :data="trendChartData" :options="chartOptions" />
        </div>
        <div v-else class="h-64 bg-white rounded-xl shadow-lg p-6 flex items-center justify-center">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      </div>
  
      <!-- 分类图 -->
      <div class="category-chart-section mb-12">
        <h2 class="text-2xl font-semibold mb-6 text-gray-700">收支分类</h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div v-if="dataReady" class="bg-white rounded-xl shadow-lg p-6" style="height: 300px;">
                <h3 class="text-lg font-semibold mb-4 text-gray-600">支出分类</h3>
                <BarChart :data="expenseCategoryChartData" :options="barOptions" />
                </div>
                <div v-if="dataReady" class="bg-white rounded-xl shadow-lg p-6" style="height: 300px;">
                <h3 class="text-lg font-semibold mb-4 text-gray-600">收入分类</h3>
                <BarChart :data="incomeCategoryChartData" :options="barOptions" />
                </div>
          <div v-else class="h-64 bg-white rounded-xl shadow-lg p-6 flex items-center justify-center">
            <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
          </div>
        </div>
      </div>
  
      <!-- 最近交易列表 -->
      <div class="transaction-list-section">
        <h2 class="text-2xl font-semibold mb-6 text-gray-700">近期交易</h2>
        <ul class="bg-white rounded-xl shadow-lg divide-y divide-gray-200">
          <li v-for="transaction in recentTransactions" :key="transaction.id" class="p-4 hover:bg-gray-50 transition duration-150 ease-in-out">
            <div class="flex justify-between items-center">
              <div>
                <p class="font-semibold text-gray-800">{{ transaction.category }}</p>
                <p class="text-sm text-gray-500">{{ formatDate(transaction.date) }}</p>
              </div>
              <p :class="[
                'font-medium',
                transaction.type === 'income' ? 'text-green-600' : 'text-red-600'
              ]">
                {{ transaction.type === 'income' ? '+' : '-' }} ¥{{ transaction.amount.toFixed(2) }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, computed, onMounted,watchEffect } from 'vue'
  import { useStore } from 'vuex'
  import dayjs from 'dayjs'
  import { Line as LineChart, Bar as BarChart } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, PointElement, CategoryScale, BarElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, PointElement, CategoryScale, BarElement)
  
  export default {
  name: 'StatisticsPage',
  components: {
    LineChart,
    BarChart
  },
  setup() {
    const dataReady = ref(false)
    const store = useStore()

    const entries = computed(() => store.getters.getEntries)
    const recentTransactions = computed(() => store.getters.getRecentEntries)

    watchEffect(() => {
      console.log('Current entries:', entries.value)
      console.log('Entries length:', entries.value.length)
    })

    const monthlyStats = computed(() => {
      const currentMonth = dayjs().month()
      const monthlyEntries = entries.value.filter(entry => dayjs(entry.date).month() === currentMonth)
      const income = monthlyEntries.filter(entry => entry.type === 'income').reduce((sum, entry) => sum + parseFloat(entry.amount), 0)
      const expense = monthlyEntries.filter(entry => entry.type === 'expense').reduce((sum, entry) => sum + parseFloat(entry.amount), 0)
      return {
        income,
        expense,
        balance: income - expense
      }
    })

    const trendChartData = computed(() => {
        if (entries.value.length === 0) {
            return { labels: [], datasets: [] }
        }

      const last6Months = Array.from({ length: 6 }, (_, i) => dayjs().subtract(i, 'month'))
      const labels = last6Months.map(date => date.format('YYYY-MM'))
      const incomeData = last6Months.map(date => 
        entries.value.filter(entry => dayjs(entry.date).format('YYYY-MM') === date.format('YYYY-MM') && entry.type === 'income')
          .reduce((sum, entry) => sum + parseFloat(entry.amount), 0)
      )
      const expenseData = last6Months.map(date => 
        entries.value.filter(entry => dayjs(entry.date).format('YYYY-MM') === date.format('YYYY-MM') && entry.type === 'expense')
          .reduce((sum, entry) => sum + parseFloat(entry.amount), 0)
      )

      return {
        labels: labels.reverse(),
        datasets: [
          {
            label: '收入',
            data: incomeData.reverse(),
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          },
          {
            label: '支出',
            data: expenseData.reverse(),
            borderColor: 'rgb(255, 99, 132)',
            tension: 0.1
          }
        ]
      }
    })

    const expenseCategoryChartData = computed(() => {
        if (entries.value.length === 0) {
            return { labels: [], datasets: [] }
        }
        const expenseCategories = {}
        entries.value.filter(entry => entry.type === 'expense').forEach(entry => {
            expenseCategories[entry.category] = (expenseCategories[entry.category] || 0) + parseFloat(entry.amount)
        })

        return {
            labels: Object.keys(expenseCategories),
            datasets: [{
            label: '支出',
            data: Object.values(expenseCategories),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }]
        }
        })

        const incomeCategoryChartData = computed(() => {
        if (entries.value.length === 0) {
            return { labels: [], datasets: [] }
        }
        const incomeCategories = {}
        entries.value.filter(entry => entry.type === 'income').forEach(entry => {
            incomeCategories[entry.category] = (incomeCategories[entry.category] || 0) + parseFloat(entry.amount)
        })

        return {
            labels: Object.keys(incomeCategories),
            datasets: [{
            label: '收入',
            data: Object.values(incomeCategories),
            backgroundColor: 'rgba(75, 192, 192, 0.5)',
            }]
        }
        })

    const formatDate = (date) => {
      return dayjs(date).format('YYYY-MM-DD')
    }

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: '过去6个月收支趋势'
        }
      }
    }

    const barOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
            display: false,
            },
            title: {
            display: true,
            text: '金额 (元)'
            }
        },
        scales: {
            y: {
            beginAtZero: true
            }
        }
        }

    onMounted(() => {
        console.log('Component mounted')
        console.log('Initial entries:', entries.value)

      setTimeout(() => {
        console.log('Setting dataReady, entries length:', entries.value.length)
        dataReady.value = entries.value.length > 0
      }, 500)
    })

    return {
      monthlyStats,
      trendChartData,
      expenseCategoryChartData,
      incomeCategoryChartData,
      recentTransactions,
      formatDate,
      dataReady,
      chartOptions,
      barOptions
    }
  }
}
</script>