<template>
    <nav class="fixed bottom-0 left-0 right-0 bg-white shadow-lg">
      <div class="max-w-screen-xl mx-auto px-4">
        <ul class="flex justify-between items-center h-16">
          <li class="flex-1">
            <router-link to="/" class="flex flex-col items-center transition-colors duration-300" :class="[$route.path === '/' ? 'text-blue-500' : 'text-gray-600']">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
              <span class="text-xs mt-1">主页</span>
              <div v-if="$route.path === '/'" class="w-1 h-1 bg-blue-500 rounded-full mt-1"></div>
            </router-link>
          </li>
          <!-- <li class="flex-1">
            <router-link to="/statistics" class="flex flex-col items-center transition-colors duration-300" :class="[$route.path === '/statistics' ? 'text-blue-500' : 'text-gray-600']">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
              </svg>
              <span class="text-xs mt-1">统计</span>
              <div v-if="$route.path === '/statistics'" class="w-1 h-1 bg-blue-500 rounded-full mt-1"></div>
            </router-link>
          </li> -->
          <li class="flex-1 flex justify-center">
            <button @click="openAddEntry" class="bg-blue-500 text-white rounded-full p-4 shadow-lg hover:bg-blue-600 transition-colors duration-300 transform -translate-y-6">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
              </svg>
            </button>
          </li>
          <li class="flex-1">
            <router-link to="/statistics" class="flex flex-col items-center transition-colors duration-300" :class="[$route.path === '/statistics' ? 'text-blue-500' : 'text-gray-600']">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
              </svg>
              <span class="text-xs mt-1">统计</span>
              <div v-if="$route.path === '/statistics'" class="w-1 h-1 bg-blue-500 rounded-full mt-1"></div>
            </router-link>
          </li>
          <!-- <li class="flex-1">
            <router-link to="/user" class="flex flex-col items-center transition-colors duration-300" :class="[$route.path === '/user' ? 'text-blue-500' : 'text-gray-600']">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
              <span class="text-xs mt-1">我的</span>
              <div v-if="$route.path === '/user'" class="w-1 h-1 bg-blue-500 rounded-full mt-1"></div>
            </router-link>
          </li>
          <li class="flex-1">
            <router-link to="/settings" class="flex flex-col items-center transition-colors duration-300" :class="[$route.path === '/settings' ? 'text-blue-500' : 'text-gray-600']">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <span class="text-xs mt-1">设置</span>
              <div v-if="$route.path === '/settings'" class="w-1 h-1 bg-blue-500 rounded-full mt-1"></div>
            </router-link>
          </li> -->
        </ul>
      </div>
    </nav>
  </template>
  
  <script>
  export default {
    name: 'NavBar',
    methods: {
      openAddEntry() {
        this.$emit('open-add-entry');
      }
    }
  }
  </script>

<style scoped>
nav {
  z-index: 1000; /* 确保导航栏在其他内容之上 */
}
</style>