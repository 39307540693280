<template>
    <div class="flex justify-center items-center h-screen">
      <h1 class="text-4xl">Login</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LoginView',
  }
  </script>
  
  <style scoped>
  </style>
  