export function getEmoji(type) {
    const emoji_map = [
        { name: '工资', emoji: '💼' },
        { name: '奖金', emoji: '🏆' },
        { name: '退款', emoji: '💳' },
        { name: '红包', emoji: '🧧' },
        { name: '兼职', emoji: '🖥️' },
        { name: '退税', emoji: '💰' },
        { name: '理财', emoji: '💹' },
        { name: '报销', emoji: '🧾' },
        { name: '购物', emoji: '🛍️' },
        { name: '餐饮', emoji: '🍔' },
        { name: '交通', emoji: '🚌' },
        { name: '娱乐', emoji: '🎮' },
        { name: '学习', emoji: '📚' },
        { name: '旅游', emoji: '✈️' },
        { name: '家庭', emoji: '🏡' },
        { name: '订阅', emoji: '📅' }
    ];

    for (let i = 0; i < emoji_map.length; i++) {
        if (emoji_map[i].name === type) {
            return emoji_map[i].emoji;
        }
    }

    // Return a default value if type is not found
    return '❓';
}
