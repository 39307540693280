<template>
  <transition name="drawer">
    <div v-if="isOpen" class="fixed inset-0 overflow-hidden z-50">
      <div class="absolute inset-0 overflow-hidden">
        <div class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
             @click="closeDrawer"></div>
        <section class="absolute inset-y-0 left-0 max-w-full flex outline-none" aria-labelledby="slide-over-heading">
          <transition name="drawer-inner"
                      enter-active-class="transform transition ease-in-out duration-300"
                      enter-class="-translate-x-full"
                      enter-to-class="translate-x-0"
                      leave-active-class="transform transition ease-in-out duration-300"
                      leave-class="translate-x-0"
                      leave-to-class="-translate-x-full">
            <div class="relative w-screen max-w-xs sm:max-w-sm">
              <div class="h-full flex flex-col bg-white shadow-xl">
                <!-- Header -->
                <div class="px-4 py-6 bg-gray-100 sm:px-6">
                  <div class="flex items-start justify-between">
                    <h2 id="slide-over-heading" class="text-lg font-medium text-gray-900">
                      用户中心
                    </h2>
                    <div class="ml-3 h-7 flex items-center">
                      <button @click="closeDrawer" class="bg-gray-100 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500">
                        <span class="sr-only">关闭面板</span>
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-gray-500">
                      管理您的账户设置和偏好
                    </p>
                  </div>
                </div>

                <!-- Content -->
                <div class="flex-1 flex flex-col justify-between overflow-y-auto">
                  <div class="px-4 sm:px-6">
                    <div class="py-4">
                      <div v-if="isLoggedIn">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">账户信息</h3>
                        <div class="mt-2 max-w-xl text-sm text-gray-500">
                          <p>欢迎回来，{{ username }}</p>
                        </div>
                      </div>
                      <div v-else>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">未登录</h3>
                        <div class="mt-2 max-w-xl text-sm text-gray-500">
                          <p>请登录以访问您的账户</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Button always at the bottom -->
                  <div class="mt-auto">
                    <div class="px-4 pb-24 bg-gray-50 border-t border-gray-200">
                      <button v-if="isLoggedIn" @click="logout"
                              class="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                        登出
                      </button>
                      <button v-else @click="showLoginModal"
                              class="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        登录
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    isLoggedIn: {
      type: Boolean,
      required: true
    },
    username: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeDrawer() {
      this.$emit('close');
    },
    showLoginModal() {
      this.$emit('showLogin');
    },
    logout() {
      this.$emit('logout');
    }
  }
}
</script>

<style scoped>
.drawer-enter-active,
.drawer-leave-active {
  transition: opacity 0.5s;
}
.drawer-enter,
.drawer-leave-to {
  opacity: 0;
}
</style>